import AccountOptions from '@/components/atoms/DialogOptions/AccountOptions'
import SettingOptions from '@/components/atoms/DialogOptions/SettingOptions'
import BellIcon from '@/components/atoms/Icon/svg/BellIcon'
import DashboardIcon from '@/components/atoms/Icon/svg/DashboardIcon'
import EditIcon from '@/components/atoms/Icon/svg/EditIcon'
import FAQIcon from '@/components/atoms/Icon/svg/FAQIcon'
import GraphIcon from '@/components/atoms/Icon/svg/GraphIcon'
import PersonIcon from '@/components/atoms/Icon/svg/PersonIcon'
import ReportIcon from '@/components/atoms/Icon/svg/ReportIcon'
import SettingIcon from '@/components/atoms/Icon/svg/SettingIcon'
import { OverflowTip } from '@/components/atoms/OverflowTip'
import { COMPANY_LINKS } from '@/constant/companyLinks'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { Notification } from '@/openapi'
import { ROUTES } from '@/routes'
import { notiApi } from '@/services/notification'
import theme from '@/theme'
import { getTime } from '@/utils/nendo'
import { InputDate } from '@/zustand/slice/inputSlice'
import useStore from '@/zustand/sotre'
import { AppBar, Backdrop, Badge, Button, Collapse, createStyles, makeStyles, Theme, Toolbar } from '@material-ui/core'
import clsx from 'clsx'
import { Link, navigate } from 'gatsby'
import React, { useState, useEffect } from 'react'
import NotificationModal from '../Modals/NotificationModal'

export type TMenuItem = {
    id: number
    text: string
    icon: JSX.Element
    path?: string
    isActive?: boolean
    children?: Required<Omit<TMenuItem, 'icon' | 'children'>>[]
}
interface THeader {
    open: boolean
    drawerWidth: number
}
export interface UnReadNotification {
    status: boolean
    quantity: number
}

function Header(props: THeader) {
    const {
        storeState,
        analysisState,
        managementUser: user,
        scopeName,
        detailEmissionFactorId,
        resetEmmisionFactor,
        isAdmin,
    } = useStore()
    const [openNotiModal, setOpenNotiModal] = useState(false)
    const [showSettingOption, setShowSettingOption] = useState(false)
    const [showAccountOption, setShowAccountOption] = useState(false)
    const [notificationList, setNotificationList] = useState<Notification[]>([])
    const [unReadNotification, setUnReadNotification] = useState<UnReadNotification>({ status: false, quantity: 0 })

    const [page, setPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [total, setTotal] = useState(0)

    const [currentTime] = useStateWithSessionStorage<InputDate | null>(SessionStorageKey.DATA_INPUT_DATE, null)
    const path = typeof window !== 'undefined' ? window.location.pathname : false
    const { year } = getTime()
    const [notiYear, setNotiyear] = useState<number>(year)

    const styleOptionBtn = {
        fontSize: 12,
        width: 'max-content',
        display: 'flex',
        alignItems: 'center',
    }

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            appBar: {
                zIndex: theme.zIndex.drawer + 4,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                backgroundColor: '#fff',
                boxShadow: 'none',
                color: '#000',
            },
            appBarShift: {
                marginLeft: props.drawerWidth,
                width: `calc(100% - ${props.drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: theme.spacing(0, 1),
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
            },
            toolBar: {
                padding: 0,
            },
            headerLogo: {
                maxWidth: 0,
                height: 74,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                overflow: 'hidden',
                transition: 'all 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
            headerLogoActive: {
                width: '100%',
                maxWidth: 73,
                borderRight: '1px solid rgba(0, 0, 0, 0.12);',
                backgroundColor: theme.colors.lightGray,
            },
            headerWrapper: {
                width: '100%',
                height: 74,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 20px',
                boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
            },
            optionsWrapper: {
                minWidth: 450,
                columnGap: 20,
            },
            optionBtn: {
                ...styleOptionBtn,
            },
            optionBtnNameUser: {
                ...styleOptionBtn,
                maxWidth: 300,
            },
            flexCenter: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
            },
            settingOptions: {
                position: 'relative',
            },
            collapse: {
                position: 'absolute',
                top: 45,
                right: 0,
                zIndex: theme.zIndex.drawer + 2,
            },
            backdrop: {
                zIndex: theme.zIndex.drawer + 1,
                color: '#fff',
            },

            companyName: {
                maxWidth: 450,
                fontSize: 12,
                fontWeight: 300,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                columnGap: 10,
                marginRight: 8,
            },
            headerText: {
                display: 'flex',
                alignItems: 'center',
                columnGap: 20,
                fontSize: 24,
                width: '90%',
                color: theme.colors.primaryN,
            },
            textBreadCrumbs: {
                color: '#000',
                textUnderlineOffset: '5px',
                textDecoration: 'underline',
                cursor: 'pointer',
            },
            comanyAvatar: {
                background: theme.colors.primaryN,
                width: 20,
                height: 20,
                fontSize: 11,
                fontWeight: 300,
            },
            activeCompanyLink: {
                textDecoration: 'underline',
                color: theme.colors.primaryN,
                cursor: 'pointer',
            },
        }),
    )
    const classes = useStyles()

    const handleBacdropClick = () => {
        setShowAccountOption(false)
        setShowSettingOption(false)
    }
    const handleNotiClick = async () => {
        const res = await notiApi.getList(undefined, notiYear, page * rowPerPage, rowPerPage)
        if (res.data.data) setNotificationList(res.data.data)
        setOpenNotiModal(true)
    }

    const handleFAQClick = () => {
        window.open(COMPANY_LINKS.TAYORI_FAQ, '_blank')
    }

    const headerTextList = [
        {
            route: ROUTES.HOME,
            content: (
                <>
                    <DashboardIcon /> ダッシュボード
                </>
            ),
        },
        {
            route: ROUTES.INPUT_MONTH,
            content: (
                <>
                    <EditIcon /> データ入力：企業単位算定
                </>
            ),
        },
        {
            route: ROUTES.INPUT_SCOPES,
            content: (
                <>
                    <EditIcon />
                    <div className="text-clame-1">
                        <Link to={ROUTES.INPUT_MONTH} className={classes.textBreadCrumbs}>
                            データ入力：企業単位算定
                        </Link>
                        {storeState.selectedSite?.name
                            ? ` > ${storeState.selectedSite.name} ${currentTime?.year || analysisState.date.year}年 ${
                                  currentTime?.month || storeState.organization?.startMonth || ''
                              }月
                              `
                            : ''}
                    </div>
                </>
            ),
        },
        {
            route: ROUTES.ANTI_WARMING,
            content: (
                <>
                    <ReportIcon /> レポーティング ＞ 温対法
                </>
            ),
        },
        {
            route: ROUTES.ENERGY_SAVING,
            content: (
                <>
                    <ReportIcon /> レポーティング ＞ 省エネ法
                </>
            ),
        },
        {
            route: ROUTES.ANALYSIS_ORGANIZATION,
            content: (
                <>
                    <GraphIcon />
                    {detailEmissionFactorId ? (
                        <div className="text-clame-1">
                            <span className={classes.textBreadCrumbs} onClick={() => resetEmmisionFactor()}>
                                データ分析：企業全体 {scopeName.split('>')[0]}
                            </span>
                            {'> '}
                            {scopeName.split('>')[1]}
                            {scopeName.split('>')[2] &&
                                scopeName.split('>')[2] !== '  ' &&
                                `> ${scopeName.split('>')[2]}`}
                        </div>
                    ) : (
                        'データ分析：企業全体'
                    )}
                </>
            ),
        },
        {
            route: ROUTES.ANALYSIS_SITE,
            content: (
                <>
                    <>
                        <GraphIcon />
                        {detailEmissionFactorId ? (
                            <div className="text-clame-1">
                                <span className={classes.textBreadCrumbs} onClick={() => resetEmmisionFactor()}>
                                    データ分析：拠点別 {scopeName.split('>')[0]}
                                </span>
                                {'> '}
                                {scopeName.split('>')[1]}
                                {scopeName.split('>')[2] &&
                                    scopeName.split('>')[2] !== '  ' &&
                                    `> ${scopeName.split('>')[2]}`}
                            </div>
                        ) : (
                            'データ分析：拠点別'
                        )}
                    </>
                </>
            ),
        },
        {
            route: ROUTES.PRIVACY_POLICY,
            content: (
                <span style={{ fontWeight: 600, fontSize: 22, color: theme.colors.primaryN }}>
                    プライバシーポリシー
                </span>
            ),
        },
        {
            route: ROUTES.ACCOUNTS_SETTING,
            content: 'ユーザー一覧',
        },
        {
            route: ROUTES.ACCOUNTS_FORM,
            content: (
                <div>
                    <Link to={ROUTES.ACCOUNTS_SETTING} className={classes.textBreadCrumbs}>
                        ユーザー一覧
                    </Link>
                    &nbsp;＞ {user ? 'ユーザー編集' : 'ユーザー追加'}
                </div>
            ),
        },
        {
            route: ROUTES.COMPANY_SETTING,
            content: '企業情報 ',
        },
        {
            route: ROUTES.PLANS,
            content: 'プラン情報 ',
        },
    ]

    const headerText =
        headerTextList.find((h) => {
            const currRoute =
                (path as string).length > 1 && (path as string).charAt((path as string).length - 1) === '/'
                    ? (path as string).substr(0, (path as string).length - 1)
                    : path
            return h.route === currRoute
        })?.content || ''

    const fetchNotification = async () => {
        const notiList = await notiApi.getList(undefined, notiYear, page * rowPerPage, rowPerPage)
        const notiStatus = await notiApi.getNew()
        if (notiList.data.data) {
            setTotal(notiList.data.total || 0)
            setNotificationList(notiList.data.data)
        }
        if (notiStatus.data.quantity) setUnReadNotification({ status: true, quantity: notiStatus.data.quantity })
    }

    useEffect(() => {
        fetchNotification()
        const interval = setInterval(() => {
            fetchNotification()
        }, 5000)
        return () => {
            clearInterval(interval)
        }
    }, [page, notiYear])

    const hanleCompanyLinkClick = () => {
        isAdmin && navigate(ROUTES.COMPANY_SETTING)
    }

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: props.open,
            })}
        >
            <Backdrop
                invisible
                open={showAccountOption || showSettingOption}
                className={classes.backdrop}
                onClick={handleBacdropClick}
            />
            <Toolbar classes={{ root: classes.toolBar }}>
                <div className={`${classes.headerLogo} ${props.open ? '' : classes.headerLogoActive}`}>
                    <img
                        src="/assets/logo/logo_EV_s.png"
                        alt="logo"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(ROUTES.HOME)}
                        width="70px"
                    />
                </div>
                <div className={classes.headerWrapper}>
                    <div className={`${classes.headerText}`}>{headerText ? headerText : ''}</div>
                    <div>
                        <div className={`${classes.optionsWrapper} ${classes.flexCenter}`}>
                            {/* <div>
                                <Button
                                    className={classes.optionBtn}
                                    startIcon={
                                        <Badge
                                            variant="dot"
                                            badgeContent={unReadNotification.quantity}
                                            color="secondary"
                                        >
                                            <BellIcon />
                                        </Badge>
                                    }
                                    onClick={handleNotiClick}
                                >
                                    お知らせ
                                </Button>
                            </div> */}
                            {/* <div>
                                <Button className={classes.optionBtn} startIcon={<FAQIcon />} onClick={handleFAQClick}>
                                    FAQ
                                </Button>
                            </div> */}
                            {isAdmin && (
                                <div className={classes.settingOptions}>
                                    <Button
                                        className={classes.optionBtn}
                                        startIcon={<SettingIcon />}
                                        onClick={() => setShowSettingOption((pre) => !pre)}
                                    >
                                        設定
                                    </Button>
                                    <Collapse timeout={100} in={showSettingOption} className={classes.collapse}>
                                        <SettingOptions />
                                    </Collapse>
                                </div>
                            )}
                            <div className={classes.settingOptions}>
                                <Button
                                    className={classes.optionBtnNameUser}
                                    startIcon={<PersonIcon />}
                                    onClick={() => setShowAccountOption((pre) => !pre)}
                                >
                                    <OverflowTip
                                        styles={{ maxWidth: '70px' }}
                                        styleTooltip={{ textTransform: 'uppercase' }}
                                    >
                                        {storeState.user?.name || ''}
                                    </OverflowTip>
                                </Button>
                                <Collapse timeout={100} in={showAccountOption} className={classes.collapse}>
                                    <AccountOptions />
                                </Collapse>
                            </div>
                        </div>
                        {storeState.organization?.name && (
                            <div className={classes.companyName}>
                                {/* <Avatar className={classes.comanyAvatar}>
                                    {storeState.organization.name.charAt(0).toLocaleUpperCase()}
                                </Avatar> */}
                                <span
                                    style={{ width: 'fit-content' }}
                                    className={`text-clame-1 ${isAdmin ? classes.activeCompanyLink : ''}`}
                                    onClick={hanleCompanyLinkClick}
                                >
                                    {storeState.organization.name}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </Toolbar>
            <NotificationModal
                open={openNotiModal}
                notificationList={notificationList}
                unReadNotification={unReadNotification}
                page={page}
                rowPerPage={rowPerPage}
                total={total}
                setYear={setNotiyear}
                setPage={setPage}
                setOpen={setOpenNotiModal}
                setNotificationList={setNotificationList}
                setUnReadNotification={setUnReadNotification}
            />
        </AppBar>
    )
}

export default Header
