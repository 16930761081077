import { CustomTooltip } from '@/components/atoms/CustomTooltip'
import ErrorIcon from '@/components/atoms/Icon/svg/ErrorIcon'
import LockIcon from '@/components/atoms/Icon/svg/LockIcon'
import { InputStatus } from '@/ghgApi'
import theme from '@/theme'
import { handleScrollToTop } from '@/utils/scrollHelper'
import { Box, Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { CSSProperties, ReactNode, useEffect } from 'react'

const SimpleTabPanel = (props: { children?: ReactNode; value: number; index: number }) => {
    return (
        <div
            role="tabpanel"
            hidden={props.value !== props.index}
            id={`simple-tabpanel-${props.index}`}
            aria-labelledby={`simple-tab-${props.index}`}
        >
            {props.value === props.index && <Box>{props.children}</Box>}
        </div>
    )
}

const a11yProps = (index: any) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

type TabStatus = InputStatus | null

export type SimpleTabProps = {
    label: string
    content: ReactNode
    status?: TabStatus
    disabled?: boolean
}

/**
 * https://material-ui.com/components/tabs/#simple-tabs
 * https://material-ui.com/components/tabs/#customized-tabs
 */
const SimpleTabs = ({
    contentSpaceTop = 30,
    ...props
}: {
    tabs: SimpleTabProps[]
    value?: number
    handleChange?: (newValue: number) => void
    isSmall?: boolean
    borderBottomColor?: string
    tabFixed?: CSSProperties
    contentSpaceTop?: number
}) => {
    /**
     * https://material-ui.com/api/tabs/#css
     */
    const useSimpleTabStyles = makeStyles(() => ({
        wrapper: {
            flexGrow: 1,
            background: 'transparent',
            position: 'relative',
        },
        tabs: {
            overflow: 'visible',
            borderBottom: `2px solid ${props.borderBottomColor || theme.colors.primaryN}`,
            height: 30,
            color: '#9DA3B5',
            minHeight: 50,
            ...props.tabFixed,
        },
        scroller: {
            overflow: 'visible !important',
        },
        indicator: {
            height: 6,
            bottom: 0,
            backgroundColor: theme.colors.primaryN,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
        },
        tab: {
            minWidth: 130,
            fontSize: 16,
            fontWeight: 600,
            opacity: 1,
            textTransform: 'initial',
            '&:first-child': {
                marginLeft: 10,
            },
        },
        tabSelected: {
            color: theme.colors.primaryN,
        },
        modal: {
            position: 'absolute' as 'absolute',
            top: 60,
            right: 0,
            zIndex: 100,
            background: '#fff',
        },
        tabLabelIcon: {
            minHeight: 46,
        },
        tabWrapperIcon: {
            flexDirection: 'row-reverse',
            columnGap: 15,
            '& > svg': {
                marginBottom: '0 !important',
            },
        },
        tabWrapperLockIcon: {
            flexDirection: 'row',
            columnGap: 5,
            '& > svg': {
                marginBottom: '0 !important',
            },
        },
        disabledStater: {
            color: '#D3D3D3',
            '& > span > svg > path': {
                fill: '#D3D3D3',
            },
        },
        fixedBg: {
            width: '100%',
            height: 15,
            position: 'fixed',
            top: (props.tabFixed?.top as number) - 7,
            background: theme.colors.white,
            zIndex: 1,
        },
    }))
    const classes = useSimpleTabStyles()
    const [value, setValue] = React.useState(props.value ?? 0)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        props.handleChange ? props.handleChange(newValue) : setValue(newValue)
        props.tabFixed && handleScrollToTop()
    }

    useEffect(() => {
        setValue(props.value ?? 0)
    }, [props.value])

    return (
        <>
            <div className={classes.wrapper}>
                {props.tabFixed ? <div className={classes.fixedBg}></div> : null}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    classes={{
                        root: classes.tabs,
                        indicator: classes.indicator,
                    }}
                >
                    {props.tabs.map((tab, index) => {
                        if (tab.disabled)
                            return (
                                <></>
                            )
                        return (
                            <Tab
                                key={`tab-${index}`}
                                label={tab.label}
                                {...a11yProps(index)}
                                classes={{
                                    root: classes.tab,
                                    selected: classes.tabSelected,
                                    wrapper: classes.tabWrapperIcon,
                                    labelIcon: classes.tabLabelIcon,
                                }}
                                disabled={tab.disabled}
                                icon={
                                    typeof tab.status !== 'undefined' && tab.status !== InputStatus.DONE ? (
                                        <ErrorIcon />
                                    ) : undefined
                                }
                                style={props.isSmall ? { minWidth: 70, fontSize: 14, fontWeight: 600 } : {}}
                            />
                        )
                    })}
                </Tabs>
                {props.tabs.map((tab, index) => (
                    <SimpleTabPanel key={index} value={value} index={index}>
                        <div style={{ paddingTop: props.tabFixed ? contentSpaceTop : 0 }}>{tab.content}</div>
                    </SimpleTabPanel>
                ))}
            </div>
        </>
    )
}

export default SimpleTabs
